.detailLoader{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;

    &Absolute{
        @extend .detailLoader;
        position: absolute;
        z-index: 999;
    }
}

.LoadingScreenContainer{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;
    z-index: 999;
}