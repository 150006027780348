.inputContainer {
    width: 100%;
    padding-top: 35px;

    &__input {
        width: 100%;
        // padding-bottom: 15px;

        fieldset {
            border-width: 2px;
            border-color: inherit !important;
        }

        &-root:first-letter{
            text-transform: uppercase !important;
        }

        &-root {
            border-color: #259ECB;
            font: 600 1rem 'Nunito' !important;

            &-error {
                border-color: red;
            }
        }

        &-focusedLabel {
            color: #259ECB !important;

            &-error {
                color: red !important;
            }
        }

        label {
            @extend .inputContainer__inputField;
        }
    }

    &__inputField {
        font: 600 1rem 'Nunito' !important;
        align-items: center;
        display: flex;

        &-chips {
            padding: 12px !important;
        }
    }

    &__inputRoot {
        width: 100%;
    }
}
.menuItem{
    &__root{
        font: 600 1rem 'Nunito' !important;
        text-transform: capitalize!important;
    }
}