.detailContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: hidden;

    &__panelView {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .leftPanelContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        &__upperContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

        &__icon {
            text-align: center;
            padding: 25px 0;

            svg {
                font-size: 5em;
            }
        }

        &__title {
            color: #259ECB;
            font: 600 1.2rem 'Nunito';
            text-align: center;
        }

        &__secondaryText {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            padding-bottom: 10px;

            @media only screen and (max-width: 1200px) {
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }

            &-title {
                font: 400 1rem 'Nunito';
                padding-right: 5px;
                white-space: nowrap;
            }

            &-text {
                font: 600 1rem 'Nunito';
                white-space: nowrap;
            }
        }

        &__svg {
            padding: 0 5px 0 0;
        }

        &__actionButtonContainer {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
        }

        &__actionButton {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            padding: 10px 15px;
            text-transform: none;
            font: 600 14px 'Nunito';
            margin-bottom: 25px;

            &-icon {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            &-text {
                width: 80%;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }
    }
}


.tabContainer {
    display: block;
    flex: 1;
    overflow-y: auto;


    &__container {
        display: flex;
        flex-direction: column;
    }

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 0;
        font: Bold 1.2rem 'Nunito';
        color: #259ECB;

        &-icon {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            svg {
                color: #259ECB;
            }
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &__rowMap {
        display: flex;
        flex: 1;
        height: 100%;
    }

    &__fieldContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 10px 5px 0;
        flex: 0 0 30%;

        &-title {
            padding: 5px 0;
            color: #484748;
            font: 600 1rem 'Nunito';
            white-space: nowrap;
        }

        &-text {
            padding: 5px 0;
            color: #484748;
            font: 400 1rem 'Nunito';
            overflow-wrap: break-word;
            overflow: hidden;
            white-space: nowrap;
        }

        &-fullWidth {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 5px 10px 5px 0;
            width: 100%;
        }
    }
}



.container {

    &__Header {
        display: flex;
        flex-direction: row;
        align-items: center;

        &Status {
            text-transform: uppercase;
            font: bold 1rem 'Nunito';

            &DRAFT {
                @extend .container__HeaderStatus;
                color: orange;
            }

            &ACTIVE {
                @extend .container__HeaderStatus;
                color: green;
            }

            &COMPLETED {
                @extend .container__HeaderStatus;
                color: #259ECB;
            }

            &INACTIVE {
                @extend .container__HeaderStatus;
                color: grey;
            }
            &ON_HOLD, &AUTHORIZATION_REQUIRED {
                @extend .container__HeaderStatus;
                color: orange;
            }
        }

        &Icon {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex: 1;
            margin: 0 20px 0 0;

            &Button {
                margin: 0 5px;
            }
        }
    }

    &__Body {
        display: flex;
        flex-direction: column;
        padding: 0 25px 25px;

        &Grid {
            @media screen and (min-width: 1281px) {
                padding: 0 0 0 20px !important;
            }

            @media screen and (max-width: 1281px) {
                padding: 20px 0 0 0px !important;
            }
        }

        &Panel {
            background-color: #ECECED;
            border-radius: 10px;
            padding: 0 15px 15px;
            box-sizing: border-box;
            overflow: auto;

            &Map {
                height: 25rem;
                margin: 15px 0 0 0;
            }

            &Empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 5rem 0;

                &Icon {
                    display: flex;
                    flex-direction: row;
                    flex: 1;

                    svg {
                        font-size: 8rem;
                        color: #CCCCCC;
                    }
                }

                &Text {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    font: bold 1.25rem 'Nunito';
                    color: #484748;
                }
            }

            &WithMargin {
                @extend .container__BodyPanel;
                margin: 20px 0 0 0;
                min-height: 15rem;
                justify-content: space-evenly;
            }

            &Documents {
                @extend .container__BodyPanel;
                margin: 20px 0 0 0;
                flex: 1;

                &:only-child {
                    margin: 0;
                }

                &Section {
                    display: flex;
                    flex-direction: column;

                    &Header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &Title {
                            font: bold .85rem 'Nunito';
                            padding: 0 10px 0 0;
                            color: #484748;
                        }

                        &Icon {
                            svg {
                                color: #484748;
                            }
                        }

                        &Divider {
                            flex: 1;
                            height: 1px;
                            background-color: #484748;
                        }
                    }

                    &Body {
                        display: flex;
                        flex-direction: column;

                        &Row {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            flex: 1;
                            margin: 10px 0 0 5px;
                            background-color: #FFF;
                            border: 1px solid #FFF;
                            border-radius: 15px;

                            &Mandatory {
                                @extend .container__BodyPanelDocumentsSectionBodyRow;
                                border-color: #F6BC4C;
                            }

                            &Icon {
                                padding: 15px 10px;
                                color: #484748;
                            }

                            &Title {
                                font: bold 1rem 'Nunito';
                                color: #484748;
                                word-break: break-all;
                            }

                            &Actions {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                flex: 1;
                                justify-content: flex-end;
                                padding: 0 15px 0 0;

                                &Icon {
                                    margin: 0 5px !important;
                                    color: #484748 !important;
                                }
                            }
                        }
                    }
                }
            }

            &Action {
                @extend .container__BodyPanel;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0;
                flex-wrap: wrap;

                &Column {
                    @extend .container__BodyPanel;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 10px;
                    flex: 1;
                }

                &Button {
                    display: flex;
                    flex: 1;
                    flex-basis: 33%;
                    padding: 10px !important;
                    margin: 15px !important;
                    background-color: #FFF !important;

                    svg {
                        font-size: 2rem !important;
                    }
                }
            }

            &Row {
                display: flex;
                flex-direction: row;
                padding: 5px 0;
            }

            &Title {
                display: flex;
                flex-direction: row;
                flex: 1;
                font: bold 1rem 'Nunito';
                color: #484748;
                text-transform: uppercase;

                &Icon {
                    color: #259ECB;
                }
            }

            &ViewAll {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 0 0 15px;
                font: bold 0.85rem 'Nunito';
                text-transform: capitalize;
                text-decoration: underline;
                cursor: pointer;
                color: #259ECB;
            }

            &Text {
                color: #9CA1A9;
                font: 600 1rem 'Nunito';
                white-space: nowrap;
                flex: 0 1 45%;

                &Highlight {
                    color: #000;
                    font: bold 1rem 'Nunito';
                    word-break: break-word;

                    &Redirect {
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }

                    &FullWidth {
                        color: #000;
                        font: bold 1rem 'Nunito';
                        flex: 1;
                    }
                }

                &FullWidth {
                    @extend .container__BodyPanelText;
                    flex: 0 1 23%;
                }

                &FullWidthLarge {
                    @extend .container__BodyPanelText;
                    flex: 0 1 15%;
                }
            }

            &Cards {
                display: flex;
                flex-direction: column;
                border: 2px solid;
                background-color: #259ECB;
                border-color: #259ECB;
                border-radius: 8px;
                flex: 1;

                &:not(:first-child){
                    margin-top: 10px;
                }

                &Remove{
                    padding: 0px 10px 0 0px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    color: #FFF;
                    flex: 1;

                    svg{
                        color: #FFF;
                    }
                }

                &Edit{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 35px;

                    svg{
                        color: #FFF;
                    }
                }

                &Amount {

                    &Input{
                        font: bold 1.65rem 'Nunito' !important;
                        color: #FFF !important;
                        max-width: 35% !important;
                    }

                    &Title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        font: bold 0.85rem 'Nunito';
                        color: #FFF;
                        padding: 15px 0 0 25px;
                    }

                    &Text {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        font: bold 1.65rem 'Nunito';
                        color: #FFF;
                        padding: 0 25px 10px 25px;
                        flex: 1;
                    }
                }

                &Transactions{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 0 15px 0 0;
                    flex: 1;

                    svg{
                        color: #FFF;
                    }
                }

                &Code {
                    &Title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        font: bold 0.85rem 'Nunito';
                        color: #FFF;
                        padding: 5px 25px;
                    }

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    font: bold 1.65rem 'Nunito';
                    color: #FFF;
                    padding: 10px 0 10px 25px;
                    letter-spacing: 10px;
                }
            }

            &CaregiversList{
                display: flex;
                flex-direction: column;
                border-bottom: 2px solid #CCC;
                padding: 10px 0;

                &:last-child{
                    border-bottom: unset;
                    padding-bottom: 0;
                }
            }
            
        }
    }
}