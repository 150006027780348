.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__Loader{
        display: flex;
        flex-direction: row;
        flex: 1;
        height: 45vh;
        justify-content: center;
        align-items: center;
    }

    &__Divider {
        width: 100%;
        margin: 25px 0 !important;
    }

    &__Button {
        padding: 10px 20px !important;
        margin-right: 25px !important;

        &Outlined {
            padding: 10px 20px !important;
            margin-left: 25px !important;
        }
    }

    &__Section {
        &Left {
            padding: 5px 25px 25px 25px !important;
        }

        &Right {
            padding: 5px 25px 25px 0 !important;
        }

        &Body {
            display: flex;
            flex-direction: column;
            padding: 0px 25px;
            max-height: calc(100vh - 385px);

            &Field {
                margin: 5px 0;

                &FullWidth {
                    @extend .container__Field;
                    width: 100%;
                }
            }

            &Location {
                display: flex;
                flex-direction: row;
                padding: 5px 5px;

                &Text {
                    font: 400 0.8rem 'Nunito';
                    color: #259ECB;
                    text-decoration: unset;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                &Header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 15px 25px;

                    &Title {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        font: 600 1rem 'Nunito';
                    }
                }
            }

            &Table {
                margin-top: 15px;
                overflow-y: auto;
                overflow-x: hidden;

                &Cell {
                    font: bold 1rem 'Nunito' !important;
                    color: #484748 !important;

                    &Hidden {
                        @extend .container__SectionBodyTableCell;
                        opacity: 0.5 !important;
                        text-decoration: line-through !important;
                    }

                    &Icon {
                        @extend .container__SectionBodyTableCell;

                        svg {
                            font-size: 1.85rem;
                        }
                    }
                }

                &Header {
                    background-color: #FAFAFA;
                    border: 2px solid #FAFAFA;
                    padding: 15px 0;
                    margin: 10px 0 0 0;
                }

                &Row {
                    background-color: #FFF;
                    border: 2px solid #FFF;
                    border-radius: 10px;
                    margin: 10px 0;
                    padding: 5px 0;
                    
                    &Fixed{
                      @extend .container__SectionBodyTableRow;
                    }


                    &SGrid {
                        position: absolute;
                        width: 100% !important;
                        height: 80px;
                        transform-origin: 50% 50% 0px;
                        border-radius: 5px;
                        color: white;
                        line-height: 40px;
                        border: 2px solid #FFF;
                        background-color: #FFF;
                        touch-action: none;

                        &DragTrigger {
                            padding: 0 10px;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #484748;
                        }
                    }

                    &Hover {
                        @extend .container__SectionBodyTableRow;

                        &:hover {
                            @extend .container__SectionBodyTableRow;
                            border: 2px solid #259ECB;
                            cursor: pointer;
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &Checked {
                        @extend .container__SectionBodyTableRow;
                        border: 2px solid #259ECB;
                    }
                }
            }


            &Empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 5rem 0;

                &Icon {
                    display: flex;
                    flex-direction: row;
                    flex: 1;

                    svg {
                        font-size: 8rem;
                        color: #CCCCCC;
                    }
                }

                &Text {
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    font: bold 1.25rem 'Nunito';
                    color: #484748;
                }
            }

            &Buttons{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                flex: 1;
                align-items: center;

                &Or{
                    padding: 0 25px;
                    font: bold 1rem 'Nunito';
                    color: #484748;
                    text-transform: uppercase;
                }
            }
        }

        &Title {
            display: flex;
            flex-direction: row;
            padding: 20px 25px;
            text-transform: uppercase;
            color: #484748;
            font: bold 1rem 'Nunito';

            &Secondary {
                @extend .container__SectionTitle;
                text-transform: unset;
                font: 600 0.85rem 'Nunito';
            }

            &Upload{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                flex: 1;
            }
        }

        &Background {
            height: 100%;
            background-color: #F4F4F4;
            box-sizing: border-box;
            border-radius: 5px;
            overflow: hidden;
        }

        &Tab {
            display: flex !important;
            flex-direction: row !important;
            justify-content: flex-start !important;
            font: 600 1rem 'Nunito';
            text-transform: capitalize;
            color: #484748;
            padding: 0 15px;

            &Selected {
                @extend .container__SectionTab;
                color: #259ECB;
            }

            &Indicator {
                background-color: #259ECB !important;
                width: 5px !important;
                border-radius: 5px !important;

                &Unset {
                    @extend .container__SectionTabIndicator;
                    background-color: transparent !important;
                }
            }

            &Loading {
                display: flex;
                flex-direction: row;
                flex: 1;
                color: #F6BC4C !important;

                &Label {
                    display: flex;
                    flex: 1;
                    justify-items: flex-start;

                    &Completed {
                        @extend .container__SectionTabLoadingLabel;
                        color: green;
                    }
                }

                &Icon {
                    &Completed {
                        fill: green !important;
                    }

                    &Pending {
                        color: #F6BC4C !important;
                    }
                }
            }
        }
    }

    &__Details {
        @extend .container;
        box-sizing: border-box;
        width: 100%;
        justify-content: center;
        align-items: center;
        min-width: 0;
    }

    &__Row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        &FullWidth {
            @extend .container__Row;
            width: 100%;
        }

        &Map {
            @extend .container__Row;
            box-sizing: border-box;
            margin: 15px 0;
            height: 100%;
            width: 100%;
            flex: 1;
        }

        &Button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            flex: 1;

            &Text {
                font: 600 1rem 'Nunito';
                color: #484748;
            }
        }

        &Title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font: bold 1rem 'Nunito';
            color: #484748;
        }
    }

    &__Field {
        margin: 10px 25px !important;

        &:first-child {
            margin: 10px 25px 10px 0 !important;
        }

        &:last-child {
            margin: 10px 0px 10px 25px !important;
        }

        &:only-child {
            margin: 10px 0px !important;
        }

        &WithMargin {
            margin: 10px 25px !important;
        }

        &White {
            background-color: #FFF !important;
        }
    }

    &__Message {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font: bold 1rem 'Nunito' !important;
        color: #484748 !important;
        background-color: #FAFAFA;
        padding: 15px 25px;
        margin: 0 25px;
        box-sizing: border-box;

        svg {
            font-size: 2.5rem;
        }
    }

    &__Accordion{

        &Title{
            display: flex;
            flex-direction: row;
            flex: 1;

            &Expand{
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &Container{
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;
            }

            &Code{
                font: bold 1rem 'Nunito';
                color: #000;
                padding: 0 15px 0 0;
            }
        }

        &Body{
            display: flex;
            flex-direction: column;

            &Container{
                padding: 0 30px;
            }
            
            &Row{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
}



.languageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
    }

    &__label {
        display: flex;
        flex-direction: row;
        font: 1rem 'Nunito';
        flex: 1;

        &Container {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            width: 100%;
        }

        &Icon {
            color: #ffe500;
        }

        &Secondary {
            display: flex;
            flex-direction: row;
            font: 0.8rem 'Nunito';
            color: #259ECB;
            padding-top: 2px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}