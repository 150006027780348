.topbarContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    
    &__logoContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        @media screen and (min-width: 1224px) {
            padding-left: 25px;
        }

        @media screen and (max-width: 1224px) {
            justify-content: center;
        }

        &-logo{
            max-width: 8rem;
        }
    }
    &-grow{
        flex-grow: 1;
    }
    &__userIcon{
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &-text{
            font: 600 14px/19px 'Nunito';
            letter-spacing: 0;
            color: #484748;
        }
        &-button{
            margin-left: 15px!important;
            color: #484748!important;
        }
    }
}



.initialIconContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: inherit;
    border: 1px solid #CCC;
    background-color: #259ECB;
    color: #FFF;
    border-radius: 50%;
    font-family: 'Nunito';
    font-weight: bold;

    &-small{
       @extend .initialIconContainer;
       font-size: 1rem;
       width: 3rem;
       height: 3rem;
    }

    &-medium{
       @extend .initialIconContainer;
       font-size: 3rem; 
       width: 6rem;
       height: 6rem;
    }

    &-large{
       @extend .initialIconContainer;
       width: 10rem;
       height: 10rem;
       font-size: 4rem; 
    }
}