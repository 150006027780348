.emptySearchContainer{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 100%;
   opacity: 0.5;
   &__icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 5em !important;
   }

   &__text{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      font: 400 18px 'Nunito' !important;

      @media only screen and (max-width:1366px) {
        font: 400 16px 'Nunito' !important;
      }
   }
}

.emptySearchTableContainer{
   position: absolute;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 25vh;
   width: 100%;
   opacity: 0.5;
   overflow: hidden;

   &__icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 5em !important;
   }

   &__text{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      font: 400 1.2rem 'Nunito' !important;
   }
}