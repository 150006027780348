
.dashboardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    &__body{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow-y: hidden;

        &-firstLogin{
            display: flex;
            flex-direction: row;
            width: 100%;
            overflow-y: hidden;
            -webkit-animation: easeAnimation 3s linear forwards;
            animation: easeAnimation 3s linear forwards;
        }
    }
}

.dashboardMobileContainer{
    display: flex;
    flex-direction: column;
    flex: 1;

    &__collapse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 35px 0;
    }
}


.upcomingVisitContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    height: 100%;
    overflow-y: hidden;
    border-right: 1px solid #CCC;
    padding: 0 10px;

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0 10px 5px;
        height: 30px;
        font: 600 16px 'Nunito';
    }

    &__body {
        overflow-y: auto;
        height: calc(100% - 50px);
        width: 100%;

        &-zero{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font: 600 14px 'Nunito';
        }

        &-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            width: auto;
            font: 600 14px 'Nunito';

            &:hover{
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 12px;
            }

            &-text{
                flex: 1;
                white-space: nowrap;

                &Centered{
                    flex: 1;
                    text-align: center;
                }
            }


            &-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex:1;
                color: #CCC;
                font: 600 12px 'Nunito';
                padding-left: 5px;
            }

            &-icons{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 10%;
                padding-right: 5px;

                a{
                    text-decoration: none;
                    padding:0;
                    margin:0;
                }
            }
        }
    }
}

.rowContainer{
    width: 100%;
    padding-left: 5px;

    &__message{
        &-hover:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.upcomingVisitMobileContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    overflow-y: auto;
    border-right: 1px solid #CCC;
    padding: 0 25px;
    height: 0;

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0 10px 5px;
        height: 30px;
        font: 600 16px 'Nunito';
    }

    &__body {
        overflow-y: auto;
        // height: calc(100% - 130px);
        width: 100%;

        &-zero{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            font: 600 14px 'Nunito';
        }

        &-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            width: auto;
            font: 600 14px 'Nunito';
            padding: 5px 0;

            &:hover{
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 12px;
            }

            &-text{
                flex: 1;
                white-space: nowrap;

                &Centered{
                    flex: 1;
                    text-align: center;
                }
            }


            &-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex:1;
                color: #FFF;
                font: 600 12px 'Nunito';
            }

            &-icons{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 10%;
                padding-right: 5px;

                a{
                    text-decoration: none;
                    padding:0;
                    margin:0;
                }
            }
        }
    }
}

.rowMobileContainer{
    width: 100%;
    padding-left: 5px;

    &__message{
        &-hover:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.welcomeComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #CCC;
    padding: 35px 0;


    @media screen and (max-width: 1024px) {
        border-bottom: unset !important;
    }

    &-grow {
        min-height: 40%;
        max-height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #CCC;

    @media screen and (max-width: 1024px) {
        border-bottom: unset !important;
    }

    }

    &__column {
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font: bold 20px 'Nunito';

        &-column {
            display: flex;
            flex-direction: column;
        }

        &-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #259ECB;
        }

        &-data {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            font: 600 26px 'Nunito';
            color: #259ECB;
        }

        &-day {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            font: 600 16px 'Nunito';
            color: #CCC;
            margin-bottom: 25px;
        }
    }

    &__icon {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &__flip {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        transform: scale(-1, 1);
    }
}



.notificationContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 40%;
    max-width: 50%;
    height: 100%;
    margin: 0 15px;
    overflow-y: hidden;

    @media only screen and (max-width: 768px){
        min-width: unset;
        max-width: unset;
    }

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font: 600 16px 'Nunito';
        padding: 10px 15px 5px 15px;
        height: 30px;

        &-icon {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-end;
            flex: 1;

            &:hover {
                svg {
                    color: #259ECB;
                }
            }
        }
    }

    &__body {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 55px);
        width: 100%;
        @media only screen and (max-width: 768px){
            max-height: 400px;
        }
        &-zero{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font: 600 14px 'Nunito';
        }

        &-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 55px;
            margin-left: 15px;
            margin-right: 15px;

            &-pending {
                @extend .notificationContainer__body-row;
                overflow: hidden;

                &:hover {
                    background-color: rgba(255, 182, 0, .1);
                }
            }

            &-done {
                @extend .notificationContainer__body-row;
                overflow: hidden;

                &:hover {
                    background-color: rgba(3, 128, 4, .1);
                }
            }

            &-text{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                font: 400 14px 'Nunito';
                flex: 1;
                max-width: 85%;
                cursor: pointer;
                &-date{
                    color: #CCC;
                    font: 400 12px 'Nunito';
                }
            }

            &-read {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 10px 15px;
                color: #CCC;
                font: 600 12px 'Nunito';
            }

            &-icon{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                max-width: 15%;
                padding-left: 10px;
            }
        }
    }
}

.statusIcon {
    padding: 15px;

    &__done {
        color: green;
        width: 30px;
        height: 30px;
    }

    &__pending {
        fill: #ff9800;
        width: 30px;
        height: 30px;
    }
}


.mobileCarouselContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #259ECB;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(72,71,72,0.49);
    -moz-box-shadow: 0px 0px 15px 0px rgba(72,71,72,0.49);
    box-shadow: 0px 0px 15px 0px rgba(72,71,72,0.49);
    &__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;

        &-dot{
            margin: 5px;
            border-radius: 50px;
            width: 1rem;
            height: 1rem;
            background-color: rgba($color: #FFF, $alpha: 0.25);
        }

        &-dotSelected{
            @extend .mobileCarouselContainer__header-dot;
            background-color: rgba($color: #FFF, $alpha: 0.75);
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        flex: 1;
        
        &-title{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0 10px 25px;
            height: 30px;
            font: 600 1.2rem 'Nunito';
            color: #FFF;
        }
    }
}

@keyframes easeAnimation{
    0%{ height:0 }
    50%{ height:50%}
    100% {height: 100%;}
}

::-webkit-scrollbar {
    width:16px;
    height:16px;
    background:inherit;
}
::-webkit-scrollbar-track:vertical {
    border-right:8px solid #CCC;
}
::-webkit-scrollbar-thumb:vertical {
    border-right:8px solid rgba(0,0,0,.2);
    border-top-left-radius: 5px;
}
::-webkit-scrollbar-track:horizontal {
    border-bottom:8px solid rgba(0,0,0,.2);

}
::-webkit-scrollbar-thumb:horizontal {
    border-bottom:8px solid rgba(0,0,0,.2);
}
::-webkit-scrollbar-corner,
    ::-webkit-resizer {background:inherit;
    border-right:8px solid rgba(255,255,255,.5); //optional
    border-bottom:8px solid rgba(255,255,255,.5); //optional
}