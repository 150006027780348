.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.2rem;
        font-family: 'Nunito';
        font-weight: bold;
        color: #484748;
        padding: 25px 35px;

        &Colored{
            @extend .container__title;
            color: #259ECB;
        }

        &-icon{
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }
    }

    &__actionContainer {
        display: flex;
        flex-direction: row;
        padding: 0px 25px 15px 25px;
    }
}

.filterButton {
    font: 600 1rem 'Nunito' !important;
    text-transform: capitalize !important;
    margin: 0 0 0 25px !important;
    border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;

    @media screen and (max-width: 768px) {
        margin: 0 0 0 15px !important;
    }

    &__badge:first-child {
        color: #FFF !important;
        font: 400 .8rem 'Nunito' !important;
        background-color: rgba($color: #259ECB, $alpha: 1) !important;
        border-radius: 25px !important;
        padding: 2px 7px !important;
    }
}

.searchInput {
    position: relative !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    flex: 1 !important;
    background-color: #FAFAFD !important;
    padding: 10px 5px !important;
    max-width: 35% !important;

    @media screen and (max-width: 1024px) {
        max-width: calc(57% - 7px) !important;
    }

    @media screen and (max-width: 768px) {
        max-width: unset !important;
    }

    &__icon {
        padding: 0 15px 0 5px !important;
    }

    &__input {
        flex: 1 !important;
        font: 600 1rem 'Nunito' !important;
    }
}

.createButtonContainer {
    display: flex !important;
    flex-direction: row !important;
    flex: 1 !important;
    align-items: center !important;
    justify-content: flex-end !important;

    button, &__button {
        font: 600 1rem 'Nunito' !important;
        background: #259ECB !important;
        text-transform: capitalize !important;
        width: unset !important;
        height: 100% !important;
    }

    &__fab {
        position: absolute !important;
        bottom: 25px !important;
        right: 25px !important;
    }
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    margin-right: 25px;

    &__leftButton {
        border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;
        border-radius: 6px 0 0 6px !important;
    }

    &__rightButton {
        border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;
        border-radius: 0 6px 6px 0 !important;
    }

    &__leftButtonActive {
        @extend .buttonGroup__leftButton;
        @extend %filterButtonOpened;

        &:hover {
            @extend %filterButtonOpened;
        }
    }

    &__rightButtonActive {
        @extend .buttonGroup__rightButton;
        @extend %filterButtonOpened;

        &:hover {
            @extend %filterButtonOpened;
        }
    }
}

%filterButton {
    border-radius: 6px;
    border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;

    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

%filterButtonOpened {
    @extend %filterButton;
    background: #259ECB !important;
    color: #FFF !important;
}


.filterDialogContainer {
    position: absolute !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2) !important;
    background: #FFF !important;
    z-index: 200 !important;
    width: calc(35% - 7px) !important;

    @media screen and (max-width: 1024px) {
        width: calc(55% - 10px) !important;
    }

    @media screen and (max-width: 768px) {
        width: calc(55% - 18px) !important;
    }
}


.formView {
    display: flex !important;
    flex-direction: column !important;
    flex: 1 !important;
    padding: 15px 0 !important;

    &__actionContainer{
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-end !important;
        border-top: 1px solid #CCC !important;
    }

    &__row {
        display: flex !important;
        flex-direction: row !important;
    }

    &__field {
        margin: 10px !important;
        flex: 1 !important;
        width: 100% !important;
    }

    &__autocomplete {
        margin: 0 10px !important;
        flex: 1 !important;
        width: 100% !important;
    }

    &__checkbox{
        margin: 0 !important;
        &-label{
            font: 400 1rem 'Nunito' !important;
            color: #484748 !important;
        }
    }

    &__iconButton{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    &__text{
        font: 600 1rem 'Nunito' !important;
        color: #259ECB !important;
    }

    &__mapRow{
            display: flex !important;
            flex: 1 !important;
            margin: 30px 0 !important;
            border: 1px solid #259ECB !important;
            border-radius: 6px !important;
            overflow: hidden !important;
            height: 20rem !important;
    }

    &__button {
        font: 600 1rem 'Nunito' !important;
        text-transform: capitalize !important;
        padding: 10px 20px !important;
        margin: 10px !important;
    }
}