.stepper {

    &__Icon {
        z-index: 1;
        color: #fff;
        width: 50px;
        height: 50px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: #A3A3A3;

        svg {
            font-size: 2rem;
        }

        &Active {
            background-color: #259ECB;
        }

        &Completed {
            background-color: #259ECB;
        }
    }
}

.container {

    &__button{
        font: 600 1rem 'Nunito';
        text-transform: capitalize;
        padding: 13px 20px !important;
    }

    &__Search {
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;
        background-color: #F4F4F4;
        border-radius: 8px;
    }

    &__List {
        margin: 10px 0;
        min-height: 50vh;
        max-height: 50vh;
        overflow-y: auto;


        &Empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5rem 0;

            &Icon {
                display: flex;
                flex-direction: row;
                flex: 1;

                svg {
                    font-size: 8rem;
                    color: #CCCCCC;
                }
            }

            &Text {
                display: flex;
                flex-direction: row;
                flex: 1;
                font: bold 1.25rem 'Nunito';
                color: #484748;
            }
        }

        &Item {
            margin: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            padding: 10px;
            border: 2px solid;
            border-color: #F4F4F4;
            border-radius: 8px;

            &Name {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;
                font: bold 1rem 'Nunito';
                color: #484748;
            }

            &Icon{
                padding: 0 10px;
            }
        }
    }

    &__Transaction{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        background-color: #F4F4F4;
        border-radius: 8px;
        margin: 5px 0;

        &Date{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex:1;
            font: bold 1rem 'Nunito';
            color: #484748;
        }

        &Amount{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex:1;
            font: bold 1rem 'Nunito';
            color: #484748;
        }

        &Result{
            display: flex;
            flex-direction: row;
            align-items: center;
            font: bold 1rem 'Nunito';
            color: #484748;
            padding-right: 25px;
        }
    }
}