.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;
    padding: 25px 25px 0 25px;


    &__Field{
        margin: 15px!important;

        &Flex{
            margin: 0 15px!important;
            display: flex;
            flex: 1;
        }
    }

    &__Row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        &FullWidth {
            @extend .container__Row;
            flex: 1;
            width: 100%;
        }
    }

    &__Column {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__VerticalScroll {
        overflow-y: auto;

        &Disabled {
            overflow-y: hidden;
        }
    }

    &__Title {
        padding: 5px 0 25px 0;
        font: bold 1.2rem 'Nunito';
        color: #484748;
    }

    &__Switch {
        display: flex;
        flex-direction: row;
        margin-right: 25px;
        border-radius: 6px;
        border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;

        // MUI-ICONBUTTON BUG - style not override without !important

        &Left {
            border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;
            border-radius: 6px 0 0 6px !important;

            &Active {
                @extend .container__SwitchLeft;
                background: #259ECB !important;
                color: #FFF !important;
            }

            &:hover {
                border-radius: 6px 0 0 6px !important;
            }
        }

        &Right {
            border: 1px solid rgba($color: #484748, $alpha: 0.25) !important;
            border-radius: 0 6px 6px 0 !important;

            &Active {
                @extend .container__SwitchRight;
                background: #259ECB !important;
                color: #FFF !important;
            }

            &:hover {
                border-radius: 0 6px 6px 0 !important;
            }
        }
    }

    &__Search {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        background-color: #FAFAFD;
        padding: 10px 5px;
        max-width: 45%;

        @media screen and (max-width: 1420px) {
            max-width: calc(60% - 7px);
        }

        @media screen and (max-width: 768px) {
            max-width: unset;
        }

        // MUI-ICONBUTTON BUG - style not override without !important
        &Icon {
            margin: 0 15px 0 5px !important;
        }

        &Input {
            flex: 1;
            font: 600 1rem 'Nunito';
        }
    }

    &__Create {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        &Button {
            font: 600 1rem 'Nunito';
            background: #259ECB;
            text-transform: capitalize;
            width: unset;
            height: 52px;
        }
    }


    &__Filter {
        margin: 0 0 0 25px !important;

        @media screen and (max-width: 768px) {
            margin: 0 0 0 15px !important;
        }

        &Badge:first-child {
            color: #FFF !important;
            font: 400 .8rem 'Nunito' !important;
            background-color: rgba($color: #259ECB, $alpha: 1) !important;
            border-radius: 25px !important;
            padding: 2px 7px !important;
        }

        &Button {
            font: 600 1rem 'Nunito' !important;
            text-transform: capitalize !important;
            height: 52px;
        }

        &Dialog {
            position: absolute;
            background-color: #FFF;
            top: 52px;
            left: 0;
            width: 100%;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
            z-index: 200;
            padding: 15px 15px;
            box-sizing: border-box;

            transition: all .2s ease-in-out;

            &Body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 10px 0 15px 0;
                width: 100%;
            }

            &Action {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }

            &Row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                flex: 1;
                width: 100%;
                padding: 5px 0;
            }

            &Field {
                margin: 0 5px !important;
            }
        }

    }

    &__Table {
        display: block;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-grow: 1;
        overflow-y: auto;
        height: 0;
        border-radius: 6px;
        margin: 25px 0px;

        &Chip {
            @extend .container__Table;
            display: flex;
            flex: 1;
            height: 0;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            overflow-y: auto;
            margin: 10px 5px;
        }

        &Header {

            &SortLabel {
                color: #484748 !important;
                font: 600 1rem 'Nunito';

                svg {
                    fill: #484748;
                    font-size: 1.5rem;
                }

                &:hover {
                    color: rgba($color: #484748, $alpha: .8);
                }
            }

            &Cell {
                background-color: rgba($color: #FFF, $alpha: 1);
                border-bottom: 1px solid rgba($color: #484748, $alpha: 0.25);
                white-space: nowrap;
            }
        }

        &Body {
            position: relative;
            border: 1px solid #CCC;

            &Cell {
                position: relative;
                color: #484748 !important;
                padding: 16px 16px !important;
                font: 400 1rem 'Nunito' !important;
                border-bottom: 1px solid rgba($color: #484748, $alpha: 0.25) !important;
                white-space: break-spaces !important;

                &Email span,
                &Email a {
                    color: #484748 !important;
                    text-decoration: unset;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                &Bold {
                    @extend .container__TableBodyCell;
                    font: 600 1rem 'Nunito' !important;
                }
            }
        }

        &NotFound {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0.5;
            position: absolute;
            flex: 1;
            width: 100%;
            margin-top: 10rem;

            &Icon {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 5em !important;
            }

            &Text {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                font: 600 1rem 'Nunito' !important;
            }
        }

        &::-webkit-scrollbar {
            width: 16px;
            height: 16px;
            background: inherit;
        }

        &::-webkit-scrollbar-track:vertical {
            border-right: 8px solid #CCC;
        }

        &::-webkit-scrollbar-thumb:vertical {
            border-right: 8px solid rgba(0, 0, 0, .2);
            border-top-left-radius: 5px;
        }

        &::-webkit-scrollbar-track:horizontal {
            border-bottom: 8px solid rgba(0, 0, 0, .2);

        }

        &::-webkit-scrollbar-thumb:horizontal {
            border-bottom: 8px solid rgba(0, 0, 0, .2);
        }

        &::-webkit-scrollbar-corner,
        &::-webkit-resizer {
            background: inherit;
            border-right: 8px solid rgba(255, 255, 255, .5); //optional
            border-bottom: 8px solid rgba(255, 255, 255, .5); //optional
        }

        &>div:first-child {
            width: 100%;
            height: 100%;
        }
    }

    &__Breadcrumbs {
        padding: 15px 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        // flex: 1;

        &Close {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }

        &Path {
            font: bold 1rem 'Nunito';
            text-decoration: unset;
            color: #484748;

            &Active {
                color: #484748;
                font: bold 1rem 'Nunito';
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__UploadDocuments {
        display: flex;
        flex: 1;
        min-height: 35rem;
        padding: 0!important;

        &List{
            display: flex;
            flex-direction: column;
            flex: 1;

            &Header{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 15px;
                border-bottom: 1px solid #CCC;

                &Button{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    flex: 1;
                }
            }

            &Body{
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                max-height: 45rem;

                &Files{
                }

                &Row{
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    align-items: center;
                    font: 600 1rem 'Nunito';
                    padding: 15px;
                    border-bottom: 1px solid #CCC;
                    // border-top: 1px solid #CCC;

                    &Expanded{
                        @extend .container__UploadDocumentsListBodyRow;
                        padding-bottom: 0;
                        border-bottom: unset;
                    }
                }
            }
        }

        &DropZone {
            margin: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 5px dashed #CCC;
            box-sizing: border-box;
            border-radius: 6px;

            &Highlight {
                @extend .container__UploadDocumentsDropZone;
                background-color: rgb(188, 185, 236);
            }

            &Text {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span {
                    display: flex;
                    flex-direction: row;
                    font: 600 1rem 'Nunito';
                    color: #CCC;
                }

                svg {
                    display: flex;
                    flex-direction: row;
                    font-size: 3rem;
                    color: #CCC;
                }

            }

            &Input {
                display: none;
            }
        }
    }
}