html,
body {
    height: 100%;
    width: 100%;
}

.login {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;

    &__formContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logoContainer {
            &__logo {
                min-width: 8rem;
                max-width: 15rem;
            }
        }

        .inputContainer {
            width: 100%;
            padding-top: 35px;
            max-width: 14vw;

            @media screen and (max-width: 1024px){
            max-width: 100%;
            }

            &__input {
                padding-bottom: 25px;
            }
        }

        .buttonContainer {
            &__button {
                background: #259ECB;
                color: #ffffff;
                text-transform: capitalize;
                padding: 5px 25px 5px 25px;
            }

            &__button:hover {
                background: #259ECB;
                color: #ffffff;
            }

            &__button:disabled {
                background: #259ECB;
            }

            &__pendingCircle {
                color: #ffffff;
                height: 24px !important;
                width: 25px !important;
                padding: 0px 5px 0px 6px;
            }
        }

        .resetPasswordContainer {
            margin-top: 35px;
            font: 400 12px 'Nunito';

            &__text {
                cursor: pointer;
                text-decoration: underline;
            }

        }
    }
}

.resetPassword{
    &__input{
        padding-bottom: 15px;
    }
}