@mixin notifier($color-default) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px 5px 15px;
    min-width: 250px;
    height: 50px;
    border-radius: 6px;
    background: $color-default;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    &-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 10px;
        color: #FFF;
        font: 600 14px 'Nunito';
    }

    &-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        font-weight: bold;
        color: #FFF;
        font: 600 14px 'Nunito';
    }

    &-close {
        padding: 0 0 0 15px;
    }

    &-white {
        color: #FFF;
    }

}

.notifierContainer {
    z-index: 9999;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 25px;

    &__notifier-success {
        @include notifier(#259ECB);
    }

    &__notifier-warning {
        @include notifier(#FF9800);
    }

    &__notifier-error {
        @include notifier(#F44435);
    }

}