.detailHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    min-height: 60px;
    padding: 0 10px;

    &__menuButton svg{
        width: 1.5rem;
        height: 1.5rem;
    }

    &__backArrowContainer {
        padding-right: 10px;
        svg {
            color: #484748;
        }
    }

    &__titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-text {
            font: 600 1rem 'Nunito';
        }

        &-secondary {
            font: 600 1rem 'Nunito';
            padding-left: 15px;
            white-space: pre-wrap;
            // overflow: scroll;
            color: #B5B5B5;
        }
    }

    &__listContainer {
        max-height: 30vh;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        &-item{
            font: 600 1rem 'Nunito';
            &:hover{
                background-color: rgba(204, 204, 204,0.25);
                cursor: pointer;
            }
        }
    }


    &__growth{
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}