
@mixin changeWidth() {
    display: flex;
    flex-direction: row;
    // width: calc(100vw - #{$width});
    flex: 1;
    height: 100%;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    box-shadow: -6px 0px 6px #00000029;
    z-index: 2;
}

html,body{
    height: 100%;
    width: 100%;
}

.authenticatedAppContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: inherit;


    &__sidebarContainer{
        background: #259ECB 0% 0% no-repeat padding-box;
        opacity: 1;
        height: 100%;
    }

    &__viewContainer{
        @include changeWidth();
        display: flex;
        flex: 1;
        width: inherit;
        overflow: auto;

        &-topbar{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 65px;
            border-bottom: 0.5px solid rgba(112, 112, 112,0.5);
            background-color: #FFF;
        }
        &-view{
            display: flex;
            flex-direction: column;
            flex: 1;
            width: inherit;
        }
    }
}

html, body{
    overflow: hidden;
}