.rightPanelContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: inherit;

    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    
    .tabsContainer{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 60px;
        &-tab{
            display: flex;
            flex-direction: row;
            align-items: center;
            border-top: 1px solid rgba(112, 112, 112, 0.25);
            border-bottom: 1px solid rgba(112, 112, 112, 0.25);
            border-right: 1px solid rgba(112, 112, 112, 0.25);
            font: 600 1rem 'Nunito';
            text-transform: capitalize;
            min-width: unset;
        }
        &-tabSelected{
            @extend .tabsContainer-tab;
            border-bottom: none;
            min-width: unset;
        }
        &-tabFiller{
            flex: 1;
            border-bottom: 1px solid rgba(112, 112, 112, 0.25);
            max-width: unset;
            min-width: 0;
        }

        &-leftScrollButton{
            position: absolute;
            background: #FFF;
            border: 1px solid #CCC;
            height: 46px;
            left: 0;
            z-index: 2;
        }

        &-rightScrollButton{
            position: absolute;
            background: #FFF;
            border: 1px solid #CCC;
            height: 46px;
            right: 0;
            z-index: 2;
        }
    }

    .viewContainer{
        padding: 15px;
        display: flex;
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        height: 0;
    }
}