.buttonContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px 0 !important;

    &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
    }

    &__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font: 600 1rem 'Nunito';
        text-transform: none;
        flex: 1;
    }

    &__menuItemText {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font: 400 1rem 'Nunito';
        text-transform: none;
        color: #259ECB;
        flex: 1;
    }

    &__menuItemTextBlack {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font: 400 1rem 'Nunito';
        text-transform: none;
        color: #484748;
        flex: 1;
    }
}


.dialogContainer {

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: #259ECB;
        color: #FFF;
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);

        &-icon {
            svg {
                color: #FFF;
                font-size: 1.5rem;
            }
        }

        h2 {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: #FFF;
            font: 600 1.25rem 'Nunito';
            margin: 0;
        }
    }

    &__text {
        font: 400 1.5rem 'Nunito';
    }
}

.formView {

    &__actionContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        // padding: 8px 25px;
    }

    &__column{
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        flex-direction: row;
    }

    &__field {
        margin: 10px!important;
        flex: 1;
        width: 100%;
    }

    &__autocomplete {
        margin: 0 10px;
        flex: 1;
        width: 100%;
    }

    &__autocompleteSecondary {
        margin: -5px 10px;
        flex: 1;
        width: 100%;
    }

    &__checkbox {
        margin: 0;

        &-label {
            font: 400 1rem 'Nunito';
            color: #484748;
        }
    }

    &__iconButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__text {
        font: 600 1rem 'Nunito';
        color: #259ECB;
    }

    &__mapRow {
        display: flex;
        flex: 1;
        margin: 30px 0 !important;
        border: 1px solid #259ECB;
        border-radius: 6px;
        overflow: hidden;
        height: 20rem;
    }

    &__button {
        font: 600 1rem 'Nunito';
        text-transform: capitalize;
        padding: 10px 20px;
    }

    &__separator {
        flex-grow: 1;
    }

    &__blackTitle{
        font: bold 1rem 'Nunito';
        color: #484748;
    }

    &__description{
        font: 400 1rem 'Nunito';
        color: #484748;
        padding-bottom: 15px;
    }

    &__blackText {
        font: 400 1rem 'Nunito';
        color: #484748;
    }

    &__rowList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px;
        margin: 10px 0;
        border-bottom: 1px solid #CCC;
    }

}