.tableScrollContainer {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    overflow-y: auto;
    height: 0;
    border-radius: 6px;
    margin: 10px 25px;

    &::-webkit-scrollbar {
        width:16px;
        height:16px;
        background:inherit;
    }
    &::-webkit-scrollbar-track:vertical {
         border-right:8px solid #CCC;
    }
    &::-webkit-scrollbar-thumb:vertical {
        border-right:8px solid rgba(0,0,0,.2);
        border-top-left-radius: 5px;
    }
    &::-webkit-scrollbar-track:horizontal {
        border-bottom:8px solid rgba(0,0,0,.2);

    }
    &::-webkit-scrollbar-thumb:horizontal {
        border-bottom:8px solid rgba(0,0,0,.2);
    }
    &::-webkit-scrollbar-corner,
        &::-webkit-resizer {background:inherit;
        border-right:8px solid rgba(255,255,255,.5); //optional
        border-bottom:8px solid rgba(255,255,255,.5); //optional
    }
        

    & > div:first-child{
        width: 100%;
        height: 100%;
    }

    &__chip {
        display: flex;
        flex: 1;
        height: 0;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-y: auto;
        margin: 10px 5px;
        

        & > div:first-child{
            width: 100%;
            height: 100%;
        }
    }
}

.tableHeaderContainer {

    &__sortLabel {
        color: #484748 !important;
        font: 600 1rem 'Nunito';

        svg {
            fill: #484748;
            font-size: 1.5rem;
        }

        &:hover {
            color: rgba($color: #484748, $alpha: .8);
        }
    }

    &__cell {
        background-color: rgba($color: #FFF, $alpha: 1);
        border-bottom: 1px solid rgba($color: #484748, $alpha: 0.25);
        white-space: nowrap;
    }
}

.tableBodyContainer {
    position: relative;
    border: 1px solid #CCC;

    &__emptySearch {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 72%;
        width: 94%;
        opacity: 0.5;
    }

    &__cell {
        position: relative;
        color: #484748 !important;
        padding: 10px 16px!important;
        font: 400 1rem 'Nunito'!important;
        border-bottom: 1px solid rgba($color: #484748, $alpha: 0.25)!important;
        white-space: break-spaces!important;

        &-email span, &-email a{
            color: #484748 !important;
            text-decoration: unset;

            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &Bold{
            @extend .tableBodyContainer__cell;
            font: 600 1rem 'Nunito'!important;
        }
    }
    &__menuItem{
        font: 400 1rem 'Nunito';
        color: #484748;
    }
}

.circularProgessLoaderContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 350px);
    width: inherit;
}