.container{
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title{
        font: bold 2rem 'Nunito';
        color: #484748;
    }

    &__secondary{
        font: bold 1rem 'Nunito';
        color: #484748;
    }

    &__button{
        padding: 20px 0 0 0;
    }

    &__icon{
        width: 30rem;
        height: 30rem;
        padding: 20px 0;
    }
}