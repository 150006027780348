.chipContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 15px;
    width: 100%;
    height: unset !important;
    box-shadow: 0px 0px 6px #00000029;
    max-height: 450px;
    max-width: calc(100%/4 - 8px*4);

    @media only screen and (max-width: 1400px) {
        max-width: calc(100%/3 - 8px*4);
    }
    @media only screen and (max-width: 1200px) {
        max-width: calc(100%/2 - 8px*4);
    }
    @media only screen and (max-width: 700px) {
        max-width: calc(100% - 8px*4);
    }

    &__grow{
        flex: 1;
    }
    &__firstRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;

        &-iconContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 15px;

            &-firstCircle {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border: 1px solid #339EFA;
                border-radius: 50%;
            }

            &-firstCircleRose {
                @extend .chipContainer__firstRow-iconContainer-firstCircle;
                border: 1px solid #ED43D3;
            }

            &-firstCircleBlack {
                @extend .chipContainer__firstRow-iconContainer-firstCircle;
                border: 1px solid #000;
            }

            &-secondCircle {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 42px;
                height: 42px;
                border: 2px solid #339EFA;
                border-radius: 50%;
            }

            &-secondCircleRose {
                @extend .chipContainer__firstRow-iconContainer-secondCircle;
                border: 2px solid #ED43D3;
            }

            &-secondCircleBlack {
                @extend .chipContainer__firstRow-iconContainer-secondCircle;
                border: 2px solid #000;
            }

            &-initials {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-align: center;
                font: Bold 1rem 'Nunito';
                letter-spacing: 1.8px;
                color: #000000;
                text-transform: uppercase;
                padding-left: 3px;
            }
        }

        &-code {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font: 400 1rem 'Nunito';
            color: #00000080;
        }

        &-dotButton {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            padding: 15px;
        }
    }

    &__secondRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font: Bold 1rem 'Nunito';
        }

        &-ssn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font: 400 1rem 'Nunito';
            letter-spacing: 1.2px;
            color: #00000080;

        }
    }

    &__rowDivider {
        border-top: 1px solid #00000080;
        height: 0.5px;
        width: 75%;
        margin: 10px 0;
    }

    &__singleRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 75%;
        padding: 5px 0;
        height: 55px;

        &-centered {
            @extend .chipContainer__singleRow;
            justify-content: center;
            height: 25px;
            margin-bottom: 5px;
        }

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            &-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font: 400 1rem 'Nunito';
                letter-spacing: 1.4px;
                color: #00000080;
            }

            &-text {
                display: block;
                text-align: start;
                font: Bold 1rem 'Nunito';
                letter-spacing: 1.4px;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &__buttonRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        &-button{
            text-transform: capitalize !important;
            font: Bold 1rem 'Nunito' !important;
        }
    }
}