.leftPanelContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 20%;
    border-top: 1px solid rgba(112, 112, 112, 0.25);
    border-right: 1px solid rgba(112, 112, 112, 0.25);
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}