.sidebarContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 250px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: 1366px) {
        transition: all .1s linear;
        width: 180px;
    }

    &__root {
        background: #259ECB !important;

        &::-webkit-scrollbar {
            width: 16px;
            height: 16px;
            background: inherit;
        }

        &::-webkit-scrollbar-track:vertical {
            border-right: 8px solid #CCC;
        }

        &::-webkit-scrollbar-thumb:vertical {
            border-right: 8px solid rgba(0, 0, 0, .2);
        }

        &::-webkit-scrollbar-track:horizontal {
            border-bottom: 8px solid rgba(0, 0, 0, .2);
        }

        &::-webkit-scrollbar-thumb:horizontal {
            border-bottom: 8px solid rgba(0, 0, 0, .2);
        }

        &::-webkit-scrollbar-corner,
        &::-webkit-resizer {
            background: inherit;
            border-right: 8px solid rgba(255, 255, 255, .5); //optional
            border-bottom: 8px solid rgba(255, 255, 255, .5); //optional
        }
    }

    &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 3px 15px 3px 0;

        &-back {
            @extend .sidebarContainer__icon;
            position: sticky;
            top: 0;
            background-color: #259ECB;
            z-index: 2;
            padding: 3px 18px 3px 0;
        }

        &-button {
            color: rgb(255, 255, 255) !important;
        }
    }

    .menuContainer {
        display: flex;
        flex-direction: column;
        height: 100%;

        &-administration {
            @extend .menuContainer;
            // margin-top: 30px;
            border-top: 1px solid rgba(255, 255, 255, 0.75);

            &__title {
                color: rgba(255, 255, 255, 0.75);
                font: 600 0.6rem 'Nunito';
                padding: 5px 25px;
                text-transform: uppercase;
                opacity: 0.75;
            }
        }

        &__link {
            text-decoration: none;
        }

        &__activeLink {
            text-decoration: none;
            align-items: center;

            .menuContainer__menu {
                width: 100%;
                align-items: center;
                justify-content: center;
                border-radius: 0;
                background: #FFFFFF45 0% 0% no-repeat padding-box;

                &-Title {
                    font: Bold .8rem 'Nunito';
                    letter-spacing: 1.43px;
                    color: rgba(255, 255, 255, 0.5);
                    opacity: 1;

                    @media only screen and (max-width: 1366px) {
                        font: Bold .8rem 'Nunito' !important;
                    }
                }

                &-Icon {
                    color: rgba(255, 255, 255, 0.5);
                }


                &Expanded {
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 25px;

                    &-Icon {
                        margin-right: 15px;
                    }
                }
            }
        }

        &__menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 60px;
            width: 100%;
            justify-content: center;
            border-radius: 0;

            &Expanded {
                @extend .menuContainer__menu;
                justify-content: flex-start;
                padding: 0 25px;

                &-Icon {
                    @extend .menuContainer__menu-Icon;
                    margin-right: 15px;
                }

                &-Title {
                    @extend .menuContainer__menu-Title;
                }
            }

            &-Icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: rgba(255, 255, 255, .5);

                svg {
                    @media only screen and (max-width: 1366px) {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            &-Title {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: left;
                font: 400 .8rem 'Nunito';
                letter-spacing: 1.43px;
                color: rgba(255, 255, 255, .5);
                text-decoration: none;
                text-transform: capitalize;

                @media only screen and (max-width: 1366px) {
                    font: 400 .8rem 'Nunito' !important;
                }
            }

            @media only screen and (max-width: 1366px) {
                height: 45px;
            }
        }

        &__logoutContainer {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &-button {
                font: 400 14px 'Nunito';
                border-top: 1px solid rgba(255, 255, 255, 0.5);

                @media only screen and (max-width: 1366px) {
                    font: 400 12px 'Nunito' !important;
                }
            }
        }
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: inherit;
    }

    &::-webkit-scrollbar-track:vertical {
        border-right: 8px solid #CCC;
    }

    &::-webkit-scrollbar-thumb:vertical {
        border-right: 8px solid rgba(0, 0, 0, .2);
    }

    &::-webkit-scrollbar-track:horizontal {
        border-bottom: 8px solid rgba(0, 0, 0, .2);
    }

    &::-webkit-scrollbar-thumb:horizontal {
        border-bottom: 8px solid rgba(0, 0, 0, .2);
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
        background: inherit;
        border-right: 8px solid rgba(255, 255, 255, .5); //optional
        border-bottom: 8px solid rgba(255, 255, 255, .5); //optional
    }
}

.sidebarContainer-close {
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    width: 80px;
}